import React, { useState } from "react"
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ResultTemplateText = ({ activity, index, sendResults, number }) => {
  const { t } = useTranslation("patient_evaluation");

  const [result, setResult] = useState("")

  const updateResult = (value) => {
    setResult(value)
    const newResults = activity.params.map((param) => {
      return {
        paramId: param._id,
        value,
      }
    })
    sendResults(newResults, index)
  }

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container direction="column" padding={2} spacing={1}>
          <Grid item container sx={{ justifyContent: "flex-end" }}>
            <Grid item>
              <Typography variant="subtitle1">
                {index + 1}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {activity.name}
            </Typography>
          </Grid>
          {activity.description && (<Grid item>
            <Typography variant="body2">
              {activity.description}
            </Typography>
          </Grid>)}
          <Grid item>
            <TextField
              variant="outlined"
              label={t("evaluation.activities.text.result")}
              type={number ? "number" : "text"}
              value={result}
              onChange={(e) => updateResult(e.target.value)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ResultTemplateText;