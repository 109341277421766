import React, { useState, useEffect } from "react";
import axios from "axios";
import { getUserId } from "../../lib/router";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Divider,
  Button,
  Typography,
  Pagination
} from "@mui/material";
import {
  Add,
  CheckCircleOutline,
  ErrorOutline
} from "@mui/icons-material"
import FullScreenModal from "../../components/FullScreenModal"
import LoadingDialog from "../../components/LoadingDialog";
import GenericDialog from "../../components/GenericDialog";
import EvaluationActivityTemplate from "./components/EvaluationActivityTemplate";
import EvaluationList from "./components/EvaluationList";

const Evaluations = () => {
  const { t } = useTranslation("evaluations_new");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(t("loading"));
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("errorLoading"))

  const [evaluations, setEvaluations] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [activityKey, setActivityKey] = useState(1);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activities, setActivities] = useState([
    {
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }
  ]);

  const resetState = () => {
    setActivityKey(1)
    setName("")
    setDescription("")
    setActivities([{
      key: 0,
      name: "",
      description: "",
      type: "",
      params: []
    }])
  }

  const openDialog = () => { setDialogOpen(true) }

  const closeDialog = () => { setDialogOpen(false) }

  const fetchEvaluations = async (page) => {
    setLoadingMessage(t("loading"));
    setLoading(true);
    try {
      const response = await axios.get(`/evaluations_new?page=${page}`);
      setEvaluations(response.data.evaluations);
      setTotalPages(response.data.pages);
      setCurrentPage(page);
    } catch (error) {
      setErrorMessage(t("errorLoading"));
      setErrorDialog(true);
    } finally {
      setLoading(false);
    }
  }

  const fetchFromPage = async (page) => {
    setCurrentPage(page);
    await fetchEvaluations(page);
  }

  const fetchAfterSuccess = async () => {
    setSuccessDialog(false);
    await fetchEvaluations(currentPage)
  }

  useEffect(() => {
    fetchEvaluations(1);
  }, [])

  const addActivity = () => {
    setActivities([...activities, {
      key: activityKey,
      name: "",
      description: "",
      type: "",
      params: []
    }])
    setActivityKey(activityKey + 1)
  }

  const updateActivity = (activity, index) => {
    const activityCopy = activities.map((item, i) => {
      if (index === i) {
        return { ...activity, key: item.key };
      }
      return item
    })
    setActivities(activityCopy)
  }

  const deleteActivity = (index) => {
    const activityCopy = Array.from(activities);
    activityCopy.splice(index, 1)
    setActivities(activityCopy)
  }

  const saveEvaluation = async () => {
    setLoadingMessage(t("saving"));
    setLoading(true);
    const body = {
      name,
      description,
      therapistId: getUserId().userId,
      activities
    };
    try {
      await axios.post("/evaluations_new", body);
      setSuccessDialog(true)
      resetState()
    } catch (error) {
      setErrorMessage(t("error"))
      setErrorDialog(true)
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container padding={2} spacing={1} direction="column">
          <Grid item container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {t("title")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={openDialog}
              >
                {t("create")}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <EvaluationList
              evaluations={evaluations}
            />
          </Grid>
          <Grid
            item
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_e, page) => fetchFromPage(page)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <FullScreenModal
        open={dialogOpen}
        handleClose={closeDialog}
        title={t("create")}
        submitButton={t("save")}
        onSubmit={saveEvaluation}
        Content={
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "94vh",
              overflow: "auto"
            }}>
            <Grid
              container
              item
              direction={"column"}
              padding={2}
              spacing={2}
              md={7}
              xs={12}
            >
              <Grid item>
                <TextField
                  variant="outlined"
                  label={t("template.name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  label={t("template.description")}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              {
                activities.map((item, index) => (
                  <EvaluationActivityTemplate
                    key={item.key}
                    activity={item}
                    index={index}
                    updateActivity={updateActivity}
                    canDelete={activities.length > 1}
                    deleteActivity={deleteActivity}
                  />
                ))
              }
              <Grid
                item
                container
                sx={{
                  justifyContent: "flex-end"
                }}>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={addActivity}
                  >
                    {t("template.add")}
                  </Button>
                </Grid>
              </Grid>
            </Grid >
          </Grid>
        }
      />
      <LoadingDialog
        open={loading}
        message={loadingMessage}
      />
      <GenericDialog
        open={successDialog}
        handleClose={fetchAfterSuccess}
        icon={<CheckCircleOutline />}
        message={t("success")}
      />
      <GenericDialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        icon={<ErrorOutline />}
        message={errorMessage}
      />
    </>
  )
}

export default Evaluations;