import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton
} from "@mui/material";
import {
  Visibility,
  Delete
} from "@mui/icons-material"

const EvaluationList = ({ evaluations }) => {
  const { t } = useTranslation("evaluations_new");

  const tableHeaders = [
    t("table.name"),
    t("table.therapist"),
    t("table.activities"),
    t("table.date"),
    t("table.actions")
  ]

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return date.split("T")[0]
  }

  const formatName = (therapist) => {
    return `${therapist.name} ${therapist.lastname}`
  }

  return (
    <TableContainer sx={{ maxHeight: "70vh" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell
                key={index}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {evaluations.map((evaluation) => (
            <TableRow
              key={evaluation._id}
            >
              <TableCell>
                {evaluation.name}
              </TableCell>
              <TableCell>
                {formatName(evaluation.therapist)}
              </TableCell>
              <TableCell>
                {evaluation.activities.length}
              </TableCell>
              <TableCell>
                {formatDate(evaluation.createdAt)}
              </TableCell>
              <TableCell>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item>
                    <IconButton disabled={true}>
                      <Visibility />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled={true}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EvaluationList;