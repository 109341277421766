import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress
} from "@mui/material";
import {
  Visibility,
  Delete
} from "@mui/icons-material"

const ResultList = ({ results, loading }) => {
  const { t } = useTranslation("patient_evaluation");

  const tableHeaders = [
    t("history.results.name"),
    t("history.results.therapist"),
    t("history.results.date"),
    t("history.results.actions")
  ]

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    return date.split("T")[0]
  }

  const formatName = (therapist) => {
    return `${therapist.name} ${therapist.lastname}`
  }

  return (
    <>
      {loading ? <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          {t("patient.loadingResults")}
        </Grid>
      </Grid> : <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((evaluation) => (
              <TableRow
                key={evaluation._id}
              >
                <TableCell>
                  {evaluation.evaluationTemplate.name}
                </TableCell>
                <TableCell>
                  {formatName(evaluation.therapist)}
                </TableCell>
                <TableCell>
                  {formatDate(evaluation.createdAt)}
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid item>
                      <IconButton disabled={true}>
                        <Visibility />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton disabled={true}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  )
}

export default ResultList;